
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "DashboardIbotech",
  computed: {
    ...mapGetters(["getUserAccess", "getCurrentUser"])
  },
})
export default class DashboardIbotech extends Vue {
  readonly getUserAccess!: UserAccess;
  readonly getCurrentUser!: any;

  created() {
    if(!this.getUserAccess.AccessIbotech.VIEW.dashboard) {
      this.$router.push({ name: 'NotFound' });
    }
  }

}
